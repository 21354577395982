import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalStoreService } from "../shared/services/local-store.service";

@Injectable()
export class LoginService {
  private baseUrl = '';
  items: any[];

  constructor(private http: HttpClient, public local: LocalStoreService) {
    this.local.setItem('url.base','https://evoltys-technologies.net:8443/api/v1');
    this.baseUrl=this.local.getItem('url.base')+'/members'; 
    let itemSub: Subscription;
  }

  login(username, password ): Observable<any> {
    return this.http.get(this.baseUrl+'/email/'+username+'/password/'+password );
  }

  recovery(email): Observable<any> {
    return this.http.get(this.baseUrl+'/recovery/'+email);
  }

  addItem(item): Observable<any> {
    let url = this.baseUrl+'/register'; 
    item._id = Math.round(Math.random() * 10000000000).toString();
    item.pubDate = new Date();
    item.username=item.email;
    item.userType="CUSTOMER";
    this.items.unshift(item);
    this.http.post(url, item).subscribe(res => console.log(res ));
    return of(this.items.slice()).pipe(delay(100));
  }
}
