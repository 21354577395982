
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { LocalStoreService } from "../../../shared/services/local-store.service";
import { DomSanitizer } from '@angular/platform-browser';
import { ReportsService } from '../reports.service';

import { CompaniesService } from '../../companies/companies.service';
import { ContactsService } from '../../contacts/contacts.service';
import { CampaignsService } from '../../campaigns/campaigns.service';
import { NotesService } from '../../notes/notes.service';
import { LeadsService } from '../../leads/leads.service';
import { ActivitiesService } from '../../activities/activities.service';
import { ProjectsService } from '../../projects/projects.service';
import { TasksService } from '../../tasks/tasks.service';
import { DealsService } from '../../deals/deals.service';
import { QuotesService } from '../../quotes/quotes.service';
import { InvoicesService } from '../../invoices/invoices.service';
import { PaymentsService } from '../../payments/payments.service';
import { DocumentsService } from '../../documents/documents.service';
import { AccountsService } from '../../accounts/accounts.service';
import { AccountingService } from '../../accounting/accounting.service';


import { TranslateService } from '@ngx-translate/core';

declare const $: any;

@Component({
  selector: 'app-reports-print',
  templateUrl: './reports-print.component.html',
  styleUrls: ['./reports-print.component.css']
})
export class ReportsPrintComponent implements OnInit {
  formData = {}

  public language: string = "en-US";

  public id: string;
  public itemForm: FormGroup;
  public items: any[];
  public itemsData: any[];
  public item: any;
  public accountItem: any;
  public companies: any[];
  public contacts: any[];
  public leads: any[];
  public deals: any[];
  public getItemSub: Subscription;
  public account: string;
  public profile: string;

  public logoLarge;
  public logoSmall;

  public activitiesType: any[];
  public dueDate;

  public url: any = "activitiesList";

  public collection: string;
  public aligns: any[];
  public types: any[];


  constructor( public translateService: TranslateService,
    public local: LocalStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public reportsService: ReportsService,
    public companiesService: CompaniesService,
    public contactsService: ContactsService,
    public campaignsService: CampaignsService,
    public documentsService: DocumentsService,
    public leadsService: LeadsService,
    public activitiesService: ActivitiesService,
    public projectsService: ProjectsService,
    public tasksService: TasksService,
    public notesService: NotesService,
    public dealsService: DealsService,
    public quotesService: QuotesService,
    public invoicesService: InvoicesService,
    public paymentsService: PaymentsService,
    private sanitizer: DomSanitizer,
    public accountsService: AccountsService,
    public accountingService: AccountingService) 
  { 
    this.profile = this.local.getItem('user.profile'); 
    this.account  = this.local.getItem('user.account'); 
    this.language = this.local.getItem('user.language');
    this.translateService.use(this.language);
  }

  ngOnInit() {
      this.id = this.route.snapshot.params["id"];
      
      this.getItemSub = this.reportsService.findById(this.id)
                          .subscribe(data => {
                              this.item = data;
                              this.aligns = this.item.aligns;
                              this.types = this.item.types;
                              this.collection = this.item.collection;
                              this.retrieveData(this.collection);
                          })  

      this.getItemSub = this.accountsService.findById(this.account)
                          .subscribe(data => {
                              this.accountItem = data;
                              this.onDownload(this.accountItem);
                          })    

  }


  retrieveData(collection) {
    switch ( collection ) {
      case "companies":
        this.getItemSub = this.companiesService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break;


      case "contacts":
        this.getItemSub = this.contactsService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break;

      case "campaigns":
        this.getItemSub = this.campaignsService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break;        

      case "leads":
        this.getItemSub = this.leadsService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break;  


      case "deals":
        this.getItemSub = this.dealsService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break; 
      
      case "activities":
          this.getItemSub = this.activitiesService.findByAccountAndIdReport(this.id)
          .subscribe(data => {
              this.itemsData = data;
          })  
          break; 

      case "tasks":
        this.getItemSub = this.tasksService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break; 

      case "projects":
        this.getItemSub = this.projectsService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break; 


      case "invoices":
        this.getItemSub = this.invoicesService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break; 

      case "quotes":
        this.getItemSub = this.quotesService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break;         

      case "payments":
        this.getItemSub = this.paymentsService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
        break;               

      case "entries":
        this.getItemSub = this.accountingService.findByAccountAndIdReport(this.id)
        .subscribe(data => {
            this.itemsData = data;
        })  
      break;  

      default: 
          break;
    }

  }


  onSubmit() {
  }

  onChange(value) {
    console.log(value);
  }

  onSelectType(value) {
    console.log(value);
  }

  onClose() {
    this.location.back();
  }  

  // download logos
  onDownload(item): void {
    this.documentsService
      .downloadByName(item.largeLogo)
      .subscribe((response: Blob) => {
          const objectUrl = URL.createObjectURL(response);
          this.logoLarge  =  this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
          setTimeout(() => {window.print();this.onClose();}, 100);
      });
  }


}



