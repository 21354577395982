import { Component, OnInit, AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { LocalStoreService } from "../../../shared/services/local-store.service";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { AllModulesService } from "../../all-modules.service";

import { PaymentsService } from '../payments.service';

import { Payments } from '../../../shared/models/payments.model';
import { Invoices } from '../../../shared/models/invoices.model';

import { TranslateService } from '@ngx-translate/core';


declare const $: any;

@Component({
  selector: 'app-payments-print',
  templateUrl: './payments-print.component.html',
  styleUrls: ['./payments-print.component.css']
})
export class PaymentsPrintComponent implements OnInit, AfterViewInit {

  public language: string = "en-US";

  formData = {}

  public id: string;
  public itemForm: FormGroup;
  public items: any[];
  public item: any;
  public companies: any[];
  public contacts: any[];
  public leads: any[];
  public deals: any[];
  public getItemSub: Subscription;

  public activitiesType: any[];
  public dueDate;

  public url: any = "activitiesList";

  public paymentFooter: string ="";

  constructor( public translateService: TranslateService,
    public local: LocalStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public paymentsService: PaymentsService) {

      this.language = this.local.getItem('user.language');
      this.translateService.use(this.language);  
     }

  ngOnInit() {
      this.id = this.route.snapshot.params["id"];

      this.getItemSub = this.paymentsService.findById(this.id)
                          .subscribe(data => {
                              this.item = data;
                              console.log(this.item);
                          })  
                
  }

 
  print() {
    window.location.href = `/payments/print/`+this.id;
  }

  onSubmit() {
  }

  ngAfterViewInit() {
    window.print();
    this.onClose();
  }


  onChange(value) {
    console.log(value);
  }

  onSelectType(value) {
    console.log(value);
  }

  onClose() {
    this.location.back();
  }  



}


