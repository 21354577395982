import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalStoreService } from "../../shared/services/local-store.service";

@Injectable()
export class CollectionsService {
  private baseUrl: string = '';
  private collection: string = 'collections';
  public owner: string;
  public account: string;
  public email: string;
  public ownerName: string;

  items: any[];

  constructor(private http: HttpClient, public local: LocalStoreService) {
    this.baseUrl=this.local.getItem('url.base')+'/'+this.collection; 
    this.owner    = this.local.getItem('user.id');  
    this.account  = this.local.getItem('user.account');
    this.email = this.local.getItem('user.email');
    this.ownerName   = this.local.getItem('user.fullname');  
    let itemSub: Subscription;
  }



  // get by name
  findByName(name): Observable<any> {
    return this.http.get(this.baseUrl+'/name/'+name);
  }



}
