<div class="container-fluid ps-md-0">
    <div class="row g-0">
      <div class="d-none d-md-flex col-md-4 col-lg-8 bg-image"></div>



      <div class="col-md-8 col-lg-4">
        <div class="login d-flex align-items-center py-5">
          <div class="container">
            <div class="row">
                
              <div class="col-md-9 col-lg-9 mx-auto">

                <div class="logo-login">
                    <img src="assets/img/logo.png" alt="Evoltys Technologies">
                </div>


                <h3 class="account-title">{{ "Signup" | translate }}</h3>
                <p class="account-subtitle">{{ "Access to your CRM Now" | translate }}</p>

                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    

                        <div class="row">
                                                                                    
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div class="row">

                                        <div class="col-sm-6">
                                            <input class="form-control" type="text" formControlName="firstName" name="firstName" placeholder='{{ "First Name" | translate }}*'>
                                        </div>

                                        <div class="col-sm-6">
                                            <input class="form-control" type="text" formControlName="lastName" name="lastName" placeholder='{{ "Last Name" | translate }}*'>
                                        </div>
                                    </div>
                                </div>
                            </div>        
                                        
                        </div>

                        <div class="form-group">
                            <input class="form-control" type="text" formControlName="email" name="eamil" placeholder='{{ "Email" | translate }}*'>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input class="form-control" type="password" formControlName="password" name="password" placeholder='{{ "Password" | translate }}*'>
                                    </div>

                                    <div class="col-sm-6">
                                        <input class="form-control" type="password" formControlName="repeatPassword" name="repeatPassword" placeholder='{{ "Repeat Password" | translate }}*'>
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input class="form-control" type="text" formControlName="organization" name="organization" placeholder='{{ "Company" | translate }}*'>
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input class="form-control" type="text" formControlName="address" name="address" placeholder='{{ "Address" | translate }}'>
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input class="form-control" type="text" formControlName="postalCode" name="postalCode" placeholder='{{ "Postal Code" | translate }}'>
                                    </div>

                                    <div class="col-sm-6">
                                        <input class="form-control" type="text" formControlName="city" name="city" placeholder='{{ "City" | translate }}'>
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                        
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-12">
                                       <select class="form-select"  formControlName="country" name="country">
                                            <option value="" disabled selected >{{"Country" | translate }}</option>    
                                            <option value="France">France</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Lao People's Democratic Republic">Laos</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="Wallis and Futuna">Wallis & Futuna</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>      
                        
                        <div class="col-sm-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <select class="form-select" formControlName="language" name="language">
                                            <option value="" disabled selected >{{ "Language" | translate }}</option>  
                                            <option value="French">Français</option>
                                            <option value="English">English</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div class="d-grid">
                            <button class="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" [disabled]="registerForm.invalid" type="submit">{{ "Register" | translate }}</button>
                        </div>

                        <div class="account-footer">
                            <a class="small" routerLink ="/login">Se connecter avec un compte existant</a>
                        </div>

                    </form>

  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<!-- 
    <div class="main-wrapper">
            <div class="account-content">
                
                <div class="container">
                
                    
                    <div class="account-box">
                        <div class="account-wrapper">

                            <div class="account-logo">
                                <a routerLink ="/dashboard"><img src="assets/img/logo.png" alt="Evoltys Technologies"></a>
                            </div>


                            <h3 class="account-title">Register</h3>
                            <p class="account-subtitle">Access to your CRM Now</p>
                            
                            <form [formGroup]="itemForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                                                                            
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <div class="row">

                                                <div class="col-sm-6">
                                                    <input class="form-control" type="text" formControlName="firstName" name="firstName" placeholder="First Name*">
                                                </div>

                                                <div class="col-sm-6">
                                                    <input class="form-control" type="text" formControlName="lastName" name="lastName" placeholder="Last Name*">
                                                </div>
                                            </div>
                                        </div>
                                    </div>        
                                                
                                </div>

                                <div class="form-group">
                                    <input class="form-control" type="text" formControlName="email" name="eamil" placeholder="Email*">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="password" formControlName="password" name="password" placeholder="Password*">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="password" formControlName="repeatPassword" name="repeatPassword" placeholder="Repeat Password*">
                                </div>
                                
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <input class="form-control" type="text" formControlName="organization" name="organization" placeholder="Company Name*">
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <input class="form-control" type="text" formControlName="address" name="address" placeholder="Address">
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <input class="form-control" type="text" formControlName="postalCode" name="postalCode" placeholder="Postal Code">
                                            </div>

                                            <div class="col-sm-6">
                                                <input class="form-control" type="text" formControlName="city" name="city" placeholder="City">
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                                
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-12">
                                               <select class="form-select"  formControlName="country" name="country" placeholder="Country">
                                                    <option value="" disabled selected >Select Country...</option>    
                                                    <option value="France">France</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Territories">French Southern Territories</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Lao People's Democratic Republic">Laos</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                    <option value="Wallis and Futuna">Wallis & Futuna</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>      
                                
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <label class="col-form-label">Account Language<span class="text-danger">*</span></label>
                                                <select class="form-select" formControlName="language" name="language" placeholder="Account Language">
                                                    <option value="" disabled selected >Select Language...</option>  
                                                    <option>French</option>
                                                    <option>English</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                
                               
                                
                                <div class="form-group text-center">
                                    <button class="btn btn-primary account-btn" [disabled]="itemForm.invalid" type="submit">Register</button>
                                </div>
                                <div class="account-footer">
                                    <p>Already have an account? <a routerLink ="/login">Login</a></p>
                                </div>


                            </form>



                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
-->