import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalStoreService } from "../../shared/services/local-store.service";

@Injectable()
export class ActivitiesService {
  private baseUrl: string = '';
  private collection: string = 'activities';
  public owner: string;
  public account: string;
  public email: string;
  public ownerName: string;

  items: any[];

  constructor(private http: HttpClient, public local: LocalStoreService) {
    this.baseUrl=this.local.getItem('url.base')+'/'+this.collection; 
    this.owner    = this.local.getItem('user.id');  
    this.account  = this.local.getItem('user.account');
    this.email = this.local.getItem('user.email');
    this.ownerName   = this.local.getItem('user.fullname');  
    let itemSub: Subscription;

  }



  // get by id
  findById(id): Observable<any> {
    return this.http.get(this.baseUrl+'/id/'+id);
  }

  // get by owner
  findByOwner(): Observable<any> {
    return this.http.get(this.baseUrl+'/owner/'+this.owner);
  }

  // get by deal
  findByDeal(id): Observable<any> {
    return this.http.get(this.baseUrl+'/deal/'+id);
  }

  // get upcoming by deal
  findUpcomingByDeal(id): Observable<any> {
    return this.http.get(this.baseUrl+'/upcoming/deal/'+id);
  }

  // get past by deal
  findPastByDeal(id): Observable<any> {
    return this.http.get(this.baseUrl+'/past/deal/'+id);
  }


  // get by deal
  findByLead(id): Observable<any> {
    return this.http.get(this.baseUrl+'/lead/'+id);
  }

  // get upcoming by lead
  findUpcomingByLead(id): Observable<any> {
    return this.http.get(this.baseUrl+'/upcoming/lead/'+id);
  }

  // get past by lead
  findPastByLead(id): Observable<any> {
    return this.http.get(this.baseUrl+'/past/lead/'+id);
  }


  // get by company
  findByCompany(id): Observable<any> {
    return this.http.get(this.baseUrl+'/company/'+id);
  }
  findUpcomingByCompany(id): Observable<any> {
    return this.http.get(this.baseUrl+'/upcoming/company/'+id);
  }
  findPastByCompany(id): Observable<any> {
    return this.http.get(this.baseUrl+'/past/company/'+id);
  }


  // get by contact
  findByContact(id): Observable<any> {
    return this.http.get(this.baseUrl+'/contact/'+id);
  }
  findUpcomingByContact(id): Observable<any> {
    return this.http.get(this.baseUrl+'/upcoming/contact/'+id);
  }
  findPastByContact(id): Observable<any> {
    return this.http.get(this.baseUrl+'/past/contact/'+id);
  }

  // get by account
  findByAccount(): Observable<any> {
    return this.http.get(this.baseUrl+'/account/'+this.account);
  }

  // get by account and Report
  findByAccountAndIdReport(id): Observable<any> {
    return this.http.get(this.baseUrl+'/account/'+this.account+'/report/'+id);
  }
  

 
  // get by account
  findByParent(id): Observable<any> {
    return this.http.get(this.baseUrl+'/parent/'+id);
  }

  findUpcomingByAccount(): Observable<any> {
    return this.http.get(this.baseUrl+'/upcoming'+'/account/'+this.account);
  }


  // get by campaign
  findByCampaign(id): Observable<any> {
    return this.http.get(this.baseUrl+'/campaign/'+id);
  }
  findUpcomingByCampaign(id): Observable<any> {
    return this.http.get(this.baseUrl+'/upcoming/campaign/'+id);
  }
  findPastByCampaign(id): Observable<any> {
    return this.http.get(this.baseUrl+'/past/campaign/'+id);
  }




  // add 
  create(item){
    item.recordId = Math.round(Math.random() * 10000000000).toString();
    item.pubDate = new Date();
    item.owner = this.owner;
    item.account = this.account;
    item.emailFrom = this.email;
    item.ownerName = this.ownerName;
    
    return this.http.post(this.baseUrl, item);
  }

  update(item){
    return this.http.put(this.baseUrl, item); 
  }

   // delete demande
   delete(row, demandes) {
    let id = row.id;
    this.items = demandes;

    let i = this.items.indexOf(row);
    let index = 0;
    for (let item of this.items) {
      if (item.id==id){
        i=index;
      } 
      index++;
    }

    this.baseUrl = this.local.getItem('url.base')+'/pricing/remove'; 
    this.http.delete(this.baseUrl+'/'+id).subscribe(res => console.log(res ));
    this.items.splice(i, 1);
    return of(this.items.slice()).pipe(delay(100));
  } 


}
