 <!-- Start Invoice-->
 <div class="invoice overflow-auto"   id="print-area">
	<div>
		<header>
			<div class="row">
				<div class="col">
					<a href="#" (click)="$event.preventDefault()">
						<img [src]="logoLarge" alt="Image" style="width:200px;">
					</a>
				</div>
			</div>

			<div class="row">
				<div class="col company-details">
					<h2 class="text-center">
						<a target="_blank" href="#" (click)="$event.preventDefault()">
                            {{ "List of Accounting Entries" | translate }} <br/>
                            {{ company.organization }} <br/>
                            
						</a>
					</h2>
                    {{ now | date : 'dd/MM/yyyy HH:mm:ss'}}
				</div>
			</div>
		</header>

        <main>
			<table>
				<thead>
					<tr>
                        <th class="text-center">{{ "Entrie #" | translate }}</th>
                        <th class="text-center">{{ "Date" | translate }}</th>
                        <th class="text-center">{{ "Invoice" | translate }}</th>
                        <th class="text-center">{{ "Payment" | translate }}</th>
                        <th class="text-center">{{ "Debit" | translate }}</th>
                        <th class="text-center">{{ "Credit" | translate }}</th>
					</tr>
				</thead>

				<tbody>
                    <tr *ngFor="let item of entries;let i = index">
                        <td>
                            {{item.entrieNumber}}
                        </td>
                        <td>{{item.creationDate | date : 'dd/MM/yyyy'}}</td>
                        <td>
                            {{item.invoiceNumber}}
                        </td>
                        <td>
                            {{item.paymentNumber}}
                        </td>

                        <td class="unit">{{item.debit  | number:'1.2-2'}}</td>
                        <td class="unit">{{item.credit  | number:'1.2-2'}}</td>
                    </tr>
				</tbody>

				<tfoot>
					<tr>
                        <td colspan ="3"></td>
						<td>{{ "Total" | translate }}</td>
						<td >{{totalDebit | number:'1.2-2'}}</td>
						<td >{{totalCredit | number:'1.2-2'}}</td>
					</tr>
					<tr>
                        <td colspan ="3"></td>
						<td>{{ "Balance" | translate }}</td>

						<td *ngIf="balance >= 0"></td>
						<td  *ngIf="balance < 0" style="color: red;">{{balance * -1 | number:'1.2-2'}}</td>
						<td  *ngIf="balance >= 0" style="color: green;">{{balance | number:'1.2-2'}}</td>
						<td *ngIf="balance < 0"></td>
					</tr>
				</tfoot>

			</table>

            
		</main>
		<footer></footer>
	</div>
	<!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
	<div></div>
</div>



