 <!-- Start Invoice-->
 <div class="invoice overflow-auto"   id="print-area">
	<div>
		<header>
			<div class="row">
				<div class="col">
					<a href="#" (click)="$event.preventDefault()">
						<img [src]="logoLarge" alt="Image" style="width:200px;">
					</a>
				</div>
			</div>

			<div class="row">
				<div class="col company-details">
					<h2 class="text-center">
						<a target="_blank" href="#" (click)="$event.preventDefault()">
						{{item?.title}} <br/><br/>
						</a>
					</h2>
				</div>
			</div>


		</header>
		<main>
			<div class="row contacts">
			</div>
			<div class="table-responsive">
					<table class="table table-center mb-0 datatable">
						<thead>
							<ng-container *ngFor="let label of item.labels;let j = index">
								<th class='{{aligns[j]}}'>{{ label | translate }}</th>
							</ng-container>
						</thead>
						<tbody>
							<tr *ngFor="let row of itemsData;let i = index" style="vertical-align: baseline;">
								<ng-container *ngFor="let field of item.fields;let j = index">
						
									<td class='{{aligns[j]}}'  *ngIf="types[j]=='text'">{{row[field]}}</td>
									<td class='{{aligns[j]}}'  *ngIf="types[j]=='ref'">{{row[field] | translate }}</td>
									<td class='{{aligns[j]}}'  *ngIf="types[j]=='date'">{{row[field] | date: 'dd/MM/yyyy'}}</td>
									<td style="text-align: right;"  *ngIf="types[j]=='decimal'">{{row[field] | number:'1.2-2'}}</td>
									<td style="text-align: right;"  *ngIf="types[j]=='number'">{{row[field]}}</td>
								</ng-container>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td colspan="2"></td>
							</tr>
						</tfoot>
					</table>
			</div>
			<div class="thanks"></div>
			<!--
			<div class="notices">
				<div> </div>
				<div> </div>
				<div> </div>
			</div>
			-->
		</main>
		<footer></footer>
	</div>
	<!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
	<div></div>
</div>
<!-- End Invoice-->

	