import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalStoreService } from "./local-store.service";

declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

@Injectable()
export class ReferentialService {
  private baseUrl: string = '';
  private collection: string = 'referential';
  public owner: string;
  public account: string;

  items: any[];

  constructor(private http: HttpClient, public local: LocalStoreService) {
    this.baseUrl=this.local.getItem('url.base'); 
    this.owner    = this.local.getItem('user.id');  
    this.account  = this.local.getItem('user.account'); 
    let itemSub: Subscription;
  }



  // get by name & language
  findByName(name, language): Observable<any> {
    return this.http.get(this.baseUrl+'/referential'+'/name/'+name+'/language/'+language);
  }


  // get by name & language
  findPipelinesByAccount(content): Observable<any> {
    return this.http.get(this.baseUrl+'/pipelines'+'/account/'+this.account+"/content/"+content);
  }

  findMembersByAccount(): Observable<any> {
    return this.http.get(this.baseUrl+'/members'+'/account/'+this.account);
  }



  
  exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvData =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }




}
