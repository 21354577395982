
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Agent } from "../shared/models/Agent.model";
import { LocalStoreService } from "../shared/services/local-store.service";
import { Subscription } from 'rxjs';
import { LoginService } from '../login/login.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  public recoverForm: FormGroup;
  public errorMsg = '';
  private _unsubscribeAll: Subject<any>;
  public items: any[];
  public getItemSub: Subscription;
  public token:string;
  public ipAddress: any;
  public agent: Agent;
  public isAuthenticated: boolean = false;

  public currentLang: string = "English";

  constructor(
    private router: Router,
    private local: LocalStoreService,
    private location: Location,
    private loginService: LoginService) { }

    ngOnInit(): void {
      this.recoverForm = new FormGroup({
        email: new FormControl('', Validators.required)
      })
    }

  recover() {
    const recoverForm = this.recoverForm.value;
    if (recoverForm.email) {
        this.loginService.recovery(recoverForm.email)
                          .subscribe(data => {
                          });
        this.onClose();
    }      
  }

  onClose() {
    this.location.back();
  }

}
