import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router'
import { LocalStoreService } from "../../shared/services/local-store.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  public id: string;
  public token: string;

  constructor(
    public local: LocalStoreService,
    private router: Router,
  ) { }

  ngOnInit() {   
    this.token = this.local.getItem('token');
    this.id = this.local.getItem('user.id');
    if (this.token != null){
      this.local.setItem('token',this.token);
      this.local.setItem('isAuthenticated',true);
      this.local.setItem('user.id',this.id);
      this.router.navigate(['/dashboard']);
    }
    else{
      this.router.navigate(['/login']);
    }
  }
}
