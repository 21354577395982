                           <!-- Start Invoice-->
							<div class="invoice overflow-auto"   id="print-area">
								<div>
									<header>
										<div class="row">
											<div class="col">
												<a href="#" (click)="$event.preventDefault()">
													<!---  
                                                        <img src="assets/images/logo-icon.png" width="80" alt="" />
                                                    -->
                                                    <img src="https://www.evoltys-technologies.com/evoltys-technologies.png" width="200" alt="{{item.payerOrganization}}" />
												</a>
											</div>
											<div class="col company-details">
												<h2 class="name">
													<a target="_blank" href="#" (click)="$event.preventDefault()">
													{{item.sellerOrganization}}
													</a>
												</h2>
												<div>{{item.sellerAddress}}</div>
												<div>{{item.sellerPostalCode}} {{item.sellerCity}}</div>
												<div>{{item.sellerCountry}}</div>
											</div>
										</div>
									</header>
									<main>
										<div class="row contacts">
											<div class="col invoice-to">
												<div class="text-gray-light">{{ "Payed by" | translate }}:</div>
												<h2 class="to">{{item.organization}}</h2>
												<div class="address">{{item.payerAddress}}</div>
												<div class="address">{{item.payerPostalCode}} {{item.payerCity}}</div>
                                                <div>{{item.payerCountry}}</div>
											</div>
											<div class="col invoice-details">
												<h1 class="invoice-id">{{ "PAYMENT" | translate }} {{item.paymentNumber}}</h1>
												<div class="date">{{ "Date of Payment" | translate }}: {{item.paymentDate |date : 'dd/MM/yyyy'}}</div>
												<div class="date">{{ "Due Date" | translate }}: {{item.dueDate |date : 'dd/MM/yyyy'}}</div>
                                                <div>{{ "Payment Method" | translate }}: {{item.paymentMethod}}</div>
											</div>
										</div>
										<table>
											<thead>
												<tr>
                                                    <th class="text-right">{{ "Reference" | translate }}</th>
													<th class="text-left">{{ "Description" | translate }}</th>
                                                    <th class="text-left">{{ "Payment Method" | translate }}</th>
													<th class="text-right">{{ "Status" | translate }}</th>
													<th class="text-right">{{ "Total" | translate }}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td class="no" style="background: #0d6efd; color: #fff">{{item.reference}}</td>
													<td class="text-left">
                                                        <h3>{{item.name}}</h3>{{item.description}}
                                                    </td>
													<td class="text-left">{{item.paymentMethod}} </td>
													<td class="qty">{{item.status}}</td>
													<td class="total">{{item.totalPaied | number:'1.2-2'}} €</td>
												</tr>
											</tbody>

                                            <tfoot>

												<tr>
                                                    <br/><br/><br/>
													<td colspan="1"></td>
													<td colspan="2">{{ "Total" | translate }}</td>
													<td>{{item.totalPaied | number:'1.2-2'}} €</td>
												</tr>
											</tfoot>

										</table>
										<br/><br/>
										<div class="thanks"></div>
										<div class="notices">
											<div>{{ "VAT Number" | translate }} : {{item.sellerVatNumber}}</div>

										</div>
									</main>
									<footer>{{paymentFooter}}</footer>
								</div>
								<!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
								<div></div>
							</div>
                            <!-- End Invoice-->

