 <!-- Start Invoice-->
 <div class="invoice overflow-auto"   id="print-area">
	<div>
		<header>
			<div class="row">
				<div class="col">
					<a href="#" (click)="$event.preventDefault()">
						<img [src]="logoLarge" alt="Image" style="width:150px;">
					</a>
				</div>
				<div class="col company-details">
					<h2 class="name">
						<a target="_blank" href="#" (click)="$event.preventDefault()">
						{{item.sellerOrganization}}
						</a>
					</h2>
					<div>{{item.sellerAddress}}</div>
					<div>{{item.sellerPostalCode}} {{item.sellerCity}}</div>
					<div>{{item.sellerCountry}}</div>
				</div>
			</div>
		</header>
		<main>
			<div class="row contacts">
				<div class="col invoice-to">
					<div class="text-gray-light">{{ "QUOTE TO" | translate }}:</div>
					<h2 class="to">{{item.buyerOrganization}}</h2>
					<div class="address">{{item.buyerAddress}}</div>
					<div class="address">{{item.buyerPostalCode}} {{item.buyerCity}}</div>
					<div>{{item.buyerCountry}}</div>
				</div>
				<div class="col invoice-details">
					<h1 class="invoice-id">{{ "QUOTE" | translate }} {{item.quoteNumber}}</h1>
					<div class="date">{{ "Date of Invoice" | translate }}: {{item.issueDate |date : 'dd/MM/yyyy'}}</div>
					<div class="date">Validity Date: {{item.dueDate |date : 'dd/MM/yyyy'}}</div>
					<div></div>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th>#</th>
						<th class="text-left">{{ "Description" | translate }}</th>
						<th class="text-right">{{ "Unit Price" | translate }}</th>
						<th class="text-right">{{ "Quantity" | translate }}</th>
						<th class="text-right">{{ "VAT" | translate }}</th>
						<th class="text-right">{{ "Total" | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of item.invoiceItems;let i = index">
						<td class="no">{{row.itemNumber}}</td>
						<td class="text-left">
							<h3>{{row.name}}</h3>{{row.description}}
						</td>
						<td class="unit">{{row.unitPrice | number:'1.2-2'}} €</td>
						<td class="qty">{{row.quantity}}</td>
						<td class="unit">{{row.itemVat | number:'1.2-2'}} €</td>
						<td class="total">{{row.itemPrice | number:'1.2-2'}} €</td>
					</tr>
				</tbody>

				<tfoot>
					<tr>
						<td colspan="3"></td>
						<td colspan="2">{{ "Subtotal" | translate }}</td>
						<td>{{item.subTotal | number:'1.2-2'}} €</td>
					</tr>
					<tr>
						<td colspan="3"></td>
						<td colspan="2">{{ "VAT" | translate }}</td>
						<td>{{item.vat | number:'1.2-2'}} €</td>
					</tr>
					<tr>
						<td colspan="3"></td>
						<td colspan="2">{{ "Total" | translate }}</td>
						<td>{{item.total | number:'1.2-2'}} €</td>
					</tr>
				</tfoot>

			</table>
			<div class="thanks"></div>
			<!--
			<div class="notices">
				<div> </div>
				<div> </div>
				<div> </div>
			</div>
			-->
		</main>
		<footer></footer>
	</div>
	<!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
	<div></div>
</div>
<!-- End Invoice-->