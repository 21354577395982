        <div class="container-fluid ps-md-0">
            <div class="row g-0">
              <div class="d-none d-md-flex col-md-4 col-lg-8 bg-image"></div>



              <div class="col-md-8 col-lg-4">
                <div class="login d-flex align-items-center py-5">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-9 col-lg-8 mx-auto">

                        <div class="logo-login">
                            <img src="assets/img/logo.png" alt="Evoltys Technologies">
                        </div>

                        <h3 class="login-heading mb-4" style="text-align : center;">Bienvenue sur notre plateforme</h3>

                        <!-- Sign In Form -->
                        <form [formGroup]="signinForm" (ngSubmit)="signin()">
                            <div class="form-floating mb-3">
                                <input type="email" class="form-control" formControlName="email" type="email" name="email">
                                <label for="floatingInput">Adresse Email</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="password" class="form-control" formControlName="password" type="password" name="password">
                                <label for="floatingPassword">Mot de Passe</label>
                            </div>
            
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" value="" id="rememberPasswordCheck">
                                <label class="form-check-label" for="rememberPasswordCheck">
                                    Se souvenir de moi
                                </label>
                            </div>
                            <div class="form-check mb-3">
                                <div class="account-footer">
                                    <p>Profitez d'un essai gratuit sans engagement <a routerLink ="/register">Inscription immédiate</a></p>
                                </div>
                            </div>
                            <div class="d-grid">
                                <button class="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit">Connexion</button>
                                <div class="text-center">
                                <a class="small" routerLink ="/forgot">Vous avez oublié votre mot de passe ?</a>
                                </div>
                            </div>
          
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>