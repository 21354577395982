  import { Component, OnInit, AfterViewInit } from '@angular/core';
  import { ActivatedRoute, Router } from "@angular/router";
  import { Subscription } from 'rxjs';
  import { Location } from '@angular/common';
  import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
  import { LocalStoreService } from "../../../shared/services/local-store.service";
  import { DocumentsService } from '../../documents/documents.service';
  import { AccountsService } from '../../accounts/accounts.service';
  import { InvoicesService } from '../../invoices/invoices.service';
  import { CompaniesService } from '../../companies/companies.service';
  

  import { DomSanitizer } from '@angular/platform-browser';
  
  
  import { TranslateService } from '@ngx-translate/core';
  
  declare const $: any;

  @Component({
    selector: 'app-entries-print',
    templateUrl: './entries-print.component.html',
    styleUrls: ['./entries-print.component.css']
  })
  export class EntriesPrintComponent implements AfterViewInit, OnInit {
  
    public language: string = "en-US";
  
    public id: string;
    public itemForm: FormGroup;
    public items: any[];
    public item: any;
    public accountItem: any;
    public company: any;
    public contacts: any[];
    public leads: any[];
    public deals: any[];
    public getItemSub: Subscription;
    public account: string;

    public itemsDisplay: any[];
    public entries: any[];
    public category:string = "All";


  
    public logoLarge;
    public logoSmall;
  
    public imageUrl: string;
    public baseUrl: string;

    public totalDebit:number = 0;
    public totalCredit:number = 0;
    public balance: number = 0;
    public now = new Date();

  
    constructor(public translateService: TranslateService,
                public local: LocalStoreService,
                private route: ActivatedRoute,
                private router: Router,
                private location: Location,
                private sanitizer: DomSanitizer,
                public accountsService: AccountsService,
                public invoicesService: InvoicesService,
                public documentsService: DocumentsService,
                public companiesService: CompaniesService) {
  
        this.account  = this.local.getItem('user.account'); 
        this.baseUrl = this.local.getItem('url.base')+'/documents';
  
        this.language = this.local.getItem('user.language');
        this.translateService.use(this.language);
  
        this.id = this.route.snapshot.params["id"];
  
        this.getItemSub = this.accountsService.findById(this.account)
        .subscribe(data => {
          this.accountItem = data;
          this.onDownload(this.accountItem);
        }) 
  
        this.getItemSub = this.accountsService.findById(this.id)
        .subscribe(data => {
            this.item = data;
        }) 
        

        this.getItemSub = this.invoicesService.findEntriesByCompany(this.id)
        .subscribe(data => {
            this.entries = data;
            for (let i = 0; i < this.entries.length; i++) {
                this.totalDebit = this.totalDebit + this.entries[i].debit;
                this.totalCredit =  this.totalCredit + this.entries[i].credit;
            }
            this.balance = this.totalCredit - this.totalDebit;
        }) 

        this.getItemSub = this.companiesService.findById(this.id)
        .subscribe(data => {
            this.company = data;
        }) 



    }
  
  
    ngOnInit(): void {
    }
  
    ngAfterViewInit() { 
      //window.print();
      //this.onClose();
    }

    onClose() {
      this.location.back();
    }  
    
  
    // download logos
    onDownload(item): void {
      this.documentsService
        .downloadByName(item.largeLogo)
        .subscribe((response: Blob) => {
            this.logoLarge = this.baseUrl+"/download/"+this.accountItem.largeLogo+"/folder/|/account/"+this.account;
            setTimeout(() => {window.print();this.onClose();}, 100);
        });
    }
  
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  
  }
  
  
