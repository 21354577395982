import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { LocalStoreService } from "../../../shared/services/local-store.service";
import { QuotesService } from '../quotes.service';
import { AccountsService } from '../../accounts/accounts.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentsService } from '../../documents/documents.service';


import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quotes-print',
  templateUrl: './quotes-print.component.html',
  styleUrls: ['./quotes-print.component.css']
})
export class QuotesPrintComponent implements  AfterViewInit, OnInit {

  public language: string = "en-US";

  public id: string;
  public itemForm: FormGroup;
  public items: any[];
  public item: any;
  public accountItem: any;
  public companies: any[];
  public contacts: any[];
  public leads: any[];
  public deals: any[];
  public getItemSub: Subscription;
  public account: string;

  public logoLarge;
  public logoSmall;

  public imageUrl: string;
  public baseUrl: string;


  constructor(public translateService: TranslateService,
              public local: LocalStoreService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              public quotesService: QuotesService,
              private sanitizer: DomSanitizer,
              public accountsService: AccountsService,
              public documentsService: DocumentsService) {
                
      this.account  = this.local.getItem('user.account'); 
      this.baseUrl = this.local.getItem('url.base')+'/documents';
      this.language = this.local.getItem('user.language');
      this.translateService.use(this.language);

      this.id = this.route.snapshot.params["id"];

      
      



     }

  ngOnInit(): void {

    this.getItemSub = this.quotesService.findById(this.id)
    .subscribe(data => {
        this.item = data;
    }) 
    
    this.getItemSub = this.accountsService.findById(this.account)
    .subscribe(data => {
      this.accountItem = data;
      this.onDownload(this.accountItem);
    })  

   

  }

  ngAfterViewInit() {
  }
  
  onClose() {
    this.location.back();
  }  

  

 

  // download logos
  onDownload(item): void {
    this.documentsService
      .downloadByName(item.largeLogo)
      .subscribe((response: Blob) => {
          //const objectUrl = URL.createObjectURL(response);
          //this.logoLarge  =  this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
          this.logoLarge = this.baseUrl+"/download/"+this.accountItem.largeLogo+"/folder/|/account/"+this.account;
          //setTimeout(() => {this.cd.detectChanges(); window.print();this.onClose();}, 100);
          setTimeout(() => {window.print();this.onClose();}, 100);
      });
  }


}


